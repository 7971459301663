.navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  background-color: #000;
  height: 100px;

  .branding {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
      height: 100px;
    }

    div {
      display: flex;
      flex-direction: column;
  
      .brand-text {
        font-size: 2rem;
        font-weight: 600;
      }
  
      .branding-subtitle {
        opacity: .7;
        font-size: 1.4rem;
      }
    }
    
  }
}