body {
  background-color: #000;
}

* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  background-color: #000;
  color: #fff;
}
.App .main-container {
  background-color: #fff;
  color: #2e2e2e;
  max-width: 1500px;
  margin: 0 auto;
}
.App .main-container .container {
  padding: 30px;
  text-align: center;
}
.App .main-container .container h1 {
  margin-bottom: 10px;
}
.App .main-container .container p {
  margin-top: 0;
}
.App .footer {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  color: #585858;
}/*# sourceMappingURL=App.css.map */