.navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  background-color: #000;
  height: 100px;
}
.navigation-bar .branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.navigation-bar .branding img {
  height: 100px;
}
.navigation-bar .branding div {
  display: flex;
  flex-direction: column;
}
.navigation-bar .branding div .brand-text {
  font-size: 2rem;
  font-weight: 600;
}
.navigation-bar .branding div .branding-subtitle {
  opacity: 0.7;
  font-size: 1.4rem;
}/*# sourceMappingURL=NavigationBar.css.map */