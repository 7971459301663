
body {
  background-color: #000;
}

* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  background-color: #000;
  color: #fff;

  .main-container {
    background-color: #fff;
    color: #2e2e2e;
    max-width: 1500px;
    margin: 0 auto;

    .container {
      padding: 30px;
      text-align: center;

      h1 {
        margin-bottom: 10px;
      }

      p {
        margin-top: 0;
      }
    }
  }

  .footer {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    color: #585858;
  }
}